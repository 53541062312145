<template>
  <f7-page class="register-page">
    <meta name="robots" content="noindex" />

    <section class="register-container">
      <div class="container">
        <img src="@/assets/images/svg/forgotpass.svg" :alt="$t.getTranslation('LBL_FORGOT_PASSWORD')" loading="lazy" />

        <div class="info">
          <h2>{{ $t.getTranslation("LBL_STEP_1_OF_3") }}</h2>
          <h1>{{ $t.getTranslation("LBL_FORGOT_PASSWORD") }}</h1>

          <p>{{ $t.getTranslation("LBL_FORGOT_PASSWORD_SUB") }}</p>
        </div>
        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.MobileCode"
            name="MobileCode"
            :required="validationRules?.MobileCode?.required"
            :minlength="validationRules?.MobileCode?.minimumLength"
            :maxlength="validationRules?.MobileCode?.maximumLength"
            :label="$t.getTranslation('LBL_MOBILE_CODE')"
            :placeholder="$t.getTranslation('LBL_MOBILE_CODE_PLACEHOLDER')"
            :info="$t.getTranslation('LBL_MOBILE_CODE_INFO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <template v-if="mobileCodeList && mobileCodeList.length > 0">
              <option v-for="mobileCode in mobileCodeList" :key="'mc_' + mobileCode.value" :value="mobileCode.value">{{ mobileCode.name }}</option>
            </template>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.MobileNumber"
            name="MobileNumber"
            :required="validationRules?.MobileNumber?.required"
            :minlength="validationRules?.MobileNumber?.minimumLength"
            :maxlength="validationRules?.MobileNumber?.maximumLength"
            :label="$t.getTranslation('LBL_MOBILE_NUMBER')"
            :placeholder="$t.getTranslation('LBL_MOBILE_NUMBER_PLACEHOLDER')"
            :info="$t.getTranslation('LBL_MOBILE_NUMBER_INFO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="tel"
            validate
            clear-button
          >
          </f7-list-input>
        </f7-list>

        <f7-button fill large raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableNextButton" @click="next">
          {{ buttonText }}
        </f7-button>

        <f7-button v-if="isAlreadyHaveCode" large @click="alreadyHaveCode">{{ $t.getTranslation("LBL_ALREADY_HAVE_CODE") }}</f7-button>

        <f7-button large @click="cancelForgotPassword"> {{ $t.getTranslation("LBL_CANCEL") }}</f7-button>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted, inject } from "vue";
import { $HTTP } from "@/utils/axios";
import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

export default defineComponent({
  name: "ForgotPasswordMobileAuthenticationPage",
  components: {},
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();

    const isButtonProcessing = ref(false);
    const countdown = ref(0);

    store.dispatch("config/fetchData", { params: { mobileCodeList: 1 } });
    const mobileCodeList = computed(() => store.getters["config/getData"]?.mobileCodeList);

    const formData = reactive({
      MobileCode: "",
      MobileNumber: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(() => {
      let forgotPasswordInfo = store.getters["forgotpassword/getData"];
      for (let keys in formData) {
        formData[keys] = forgotPasswordInfo[keys];
      }
    });

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const checkAllowNextOTPRequest = () => {
      let forgotPasswordInfo = store.getters["forgotpassword/getData"];
      let newUnixTime = new Date().getTime() / 1000;

      if (forgotPasswordInfo && (formData.MobileCode == "" || forgotPasswordInfo.MobileCode == formData.MobileCode) && (formData.MobileNumber == "" || forgotPasswordInfo.MobileNumber == formData.MobileNumber)) {
        if (newUnixTime <= forgotPasswordInfo.OTPNextRequest) {
          let countDownInterval = setInterval(() => {
            let newUnixTime = new Date().getTime() / 1000;
            let distance = (forgotPasswordInfo.OTPNextRequest - newUnixTime) * 1000;

            if (distance > 0) {
              let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
              let seconds = Math.floor((distance % (1000 * 60)) / 1000);

              countdown.value = `${minutes}:${helpers.padStart(seconds, 2, "0")}`;
            } else {
              countdown.value = 0;
              clearInterval(countDownInterval);
            }
          }, 1000);
        } else {
          countdown.value = 0;
          return true;
        }
      } else {
        countdown.value = 0;
        return true;
      }
    };

    const isDisableNextButton = computed(() => {
      let isValid = validate(false);

      checkAllowNextOTPRequest();

      return !isValid || countdown.value != 0;
    });

    const buttonText = computed(() => {
      return countdown.value != 0 ? `${$t.getTranslation("LBL_VERIFY")} - ${countdown.value}` : $t.getTranslation("LBL_VERIFY");
    });

    const isAlreadyHaveCode = computed(() => {
      return countdown.value != 0 ? true : false;
    });

    const next = async () => {
      try {
        let isValid = validate(true);

        if (isValid) {
          isButtonProcessing.value = true;
          helpers.showLoader();

          let res = await $HTTP.post("/mobile/forgotpassword/otp/generate", formData);

          if (res && res.status === 200 && res.data && res.data.data) {
            isButtonProcessing.value = false;
            helpers.hideLoader();

            let returnData = res.data.data;
            if (returnData && returnData.isOTPGenerated) {
              store.dispatch("forgotpassword/setData", {
                ...formData,
                IsOTPGenerated: 1,
                OTPNextRequest: returnData.OTPNextRequest,
              });

              props.f7router.navigate("/forgotpassword/mobileverification/");
              return;
            }
          }

          throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
        }
      } catch (err) {
        isButtonProcessing.value = false;
        helpers.hideLoader();

        helpers.catchError(err, true);
      }
    };

    const alreadyHaveCode = () => {
      props.f7router.navigate("/forgotpassword/mobileverification/");
    };

    const cancelForgotPassword = () => {
      store.dispatch("forgotpassword/resetData");
      props.f7router.navigate("/login/");
    };

    return {
      mobileCodeList,
      formData,
      validationRules,
      buttonText,
      isButtonProcessing,
      isDisableNextButton,
      next,
      isAlreadyHaveCode,
      alreadyHaveCode,
      cancelForgotPassword,
    };
  },
});
</script>

<style scoped>
.register-container {
  margin-top: 60px;
}
</style>
